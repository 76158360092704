import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ConversationContext from '../../context/Conversation.context';
import HCPHistory from './HCPHistory.subSection';
import GeneralChatHistory from './GeneralChatHistory.subSection';
import Button from '../../components/Button/Button';
import Profile from './Profile.subsection';
import { janssenlogonew } from '../../assets/icons';
import { SCOPE_TYPE } from '../../constants/scope.constant';
import AdminTools from './AdminTools.subSection';
import RepAccessHistory from './RepAccessHistory.subSection';

/**
 * The `ChatHistoryPanel` function is a React component that renders the chat history panel section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id, selected HCP, and set the conversation id.
 * The `useCallback` hook is used to memoize the function that handles the new chat click.
 * @returns The `ChatHistoryPanel` function returns a React component.
 */
function ChatHistoryPanel({ scope, setConversationTool }) {
  const { setConversationId, setSelectedHcp, setIsHcpConversation } =
    useContext(ConversationContext);
  const { t } = useTranslation('common');

  const onNewChat = useCallback(() => {
    setConversationId('');
    setSelectedHcp(null);
    setIsHcpConversation(null);
  }, []);

  return (
    <div className="flex flex-col h-full">
      <img src={janssenlogonew} alt="janssen" className="mb-2" />
      <div className="flex-1 overflow-hidden flex flex-col">
        <div
          className={classnames('', {
            'h-full': scope === SCOPE_TYPE.REP_ACCESS,
          })}
        >
          {scope === SCOPE_TYPE.REP_ACCESS && <RepAccessHistory />}
        </div>
        {scope === SCOPE_TYPE.COPILOT && (
          <div className="flex flex-col flex-1 overflow-hidden bg-secondary-500 p-2">
            <div className="px-4">
              <Button
                dataTestid="new-conversation"
                content={t('button.newConversation')}
                onClick={onNewChat}
              />
            </div>
            <div className="flex-1 overflow-hidden flex-col p-4">
              <GeneralChatHistory />
              <HCPHistory />
            </div>
          </div>
        )}
      </div>
      <AdminTools setConversationTool={setConversationTool} />
      <Profile />
    </div>
  );
}

ChatHistoryPanel.propTypes = {
  scope: PropTypes.string,
  setConversationTool: PropTypes.func.isRequired,
};

ChatHistoryPanel.defaultProps = {
  scope: SCOPE_TYPE.COPILOT,
};
export default ChatHistoryPanel;
