const ADMIN_USERS = [
  '702444720',
  '152889171',
  '643033357',
  '1074357',
  '1041982',
  '370382',
  '1023096',
  '1071636',
  '1072524',
  '1015191',
  '152957411',
  '152914657',
  '152229203',
  '1074457',
  '152242321',
  '1033835',
  '1046480',
  '1044001',
  '152227088',
  '84692',
  '1033676',
  '1076503',
  '1033835',
  '1041982',
  '1023096',
  '101933',
  '1030156',
  '152207662',
  '152221476',
  '359209',
  '152916576',
];

export default ADMIN_USERS;
