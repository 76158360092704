import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import authFactory from '../../utility/auth/authFactory';
import TileLink from '../../components/TileLink/TileLink';

function Landing({ setShowLanding }) {
  const { t } = useTranslation('common');
  const authScope = localStorage.getItem('authScope');

  const getUserName = () => {
    const auth = authFactory();
    const pingIdProfile = auth.getProfile();
    return get(pingIdProfile, 'given_name', '');
  };

  const userName = getUserName();

  const copyrightYear = new Date().getFullYear();

  return (
    <div className="h-full">
      <div className="p-5 h-full">
        <div className="bg-white h-full">
          <div className="h-full">
            <div className="flex-1 overflow-y-auto">
              <div className="bg-secondary-100 p-3">
                <h1>
                  {t('common.greeting')} {userName},<br />
                </h1>
                <aside className="mb-2">{t('landingPage.introText')}</aside>
                <div className="flex flex-row gap-4">
                  <a
                    href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Fjnj.accesssync.com%2F&data=05%7C02%7Cesherwoo%40its.jnj.com%7C2d0fab48420341c9f83908dc48f07ce0%7C3ac94b33913548219502eafda6592a35%7C0%7C0%7C638465444932128158%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=047seEPdtP8q76OQhz8p3p1hqAiyshczNNqF2Q0sbhQ%3D&reserved=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TileLink
                      title={t('landingPage.tiles.territoryIntelligence.title')}
                      subtext={t(
                        'landingPage.tiles.territoryIntelligence.subtext',
                      )}
                    />
                  </a>

                  {authScope !== 'tremfya_gi' &&
                    authScope !== 'derms' &&
                    authScope !== 'neuro_science' && (
                      <TileLink
                        title={t('landingPage.tiles.copilot.title')}
                        subtext={t('landingPage.tiles.copilot.subtext')}
                        onClick={() => setShowLanding(false)}
                      />
                    )}

                  {authScope !== 'tremfya_gi' &&
                    authScope !== 'neuro_science' && (
                      <a
                        href="https://jnj.sharepoint.com/teams/WithMePortal/SitePages/RheumDerm.aspx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TileLink
                          title={t('landingPage.tiles.cxSharepoint.title')}
                          subtext={t('landingPage.tiles.cxSharepoint.subtext')}
                        />
                      </a>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-secondary-800 mb-1 mt-3" />
      <span className="m-1 self-end">
        {t('copilotLandingPage.copyright', { year: copyrightYear })}
      </span>
    </div>
  );
}

Landing.propTypes = {
  setShowLanding: PropTypes.func.isRequired,
};

export default Landing;
